<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-custom border-bottom border-danger">
        <div class="container">
            <span class="navbar-brand mr-auto"><a class="phone-link" href="tel:+35987 630 5600">087 630 5600</a></span>
            <!-- data-toggle="collapse" -->
            <button class="navbar-toggler"
            type="button"
            @click="menuHidden = !menuHidden"
            :class="{collapsed: menuHidden}"
            data-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" 
            aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :class="{show: !menuHidden}" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" @click="menuHidden = !menuHidden" to="/">Начало</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" @click="menuHidden = !menuHidden" to="/about">За Нас</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" @click="menuHidden = !menuHidden" to="/customers">Клиенти</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a 
                        class="nav-link dropdown-toggle" 
                        href="#" 
                        id="navbarDropdown" 
                        role="button" 
                        data-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false">Услуги</a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" @click="menuHidden = !menuHidden" to="/services/offices">За Офиси</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" @click="menuHidden = !menuHidden" to="/services/homes">За Домове</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" @click="menuHidden = !menuHidden" to="/services/industry">За Индустриални обекти</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" @click="menuHidden = !menuHidden" to="/services/ddd">ДДД</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" @click="menuHidden = !menuHidden" to="/services/greening">Озеленяване</router-link>
                            <div class="dropdown-divider"></div>
                            <router-link class="dropdown-item" @click="menuHidden = !menuHidden" to="/services/windows">Остъкление</router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" @click="menuHidden = !menuHidden" to="/contacts">Контакти</router-link>
                    </li>
                </ul>
                <Social @click="menuHidden = !menuHidden" />
            </div>
        </div>
    </nav>
</template>

<script>
    import Social from "./includes/header/Social"

    export default {
        name: "Header",
        data() {
            return {
                menuHidden: true,
            }
        },
        components: {
            Social,
        }
    }
</script>