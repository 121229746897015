import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { homes } from "./includes/homes";
import { offices } from "./includes/offices";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Cleaner BG - Начало",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                },
                {
                    property: 'og:image',
                    content: process.env.BASE_URL + require('../assets/cleaner-image.png')
                }
            ]
        }
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import ("../views/About.vue"),
        meta: {
            title: "Cleaner BG - За нас",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        }
    },
    {
        path: "/customers",
        name: "Customers",
        component: () =>
            import ("../views/Customers.vue"),
        meta: {
            title: "Cleaner BG - Клиенти",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        }
    },
    {
        path: "/services",
        name: "Services",
        component: () =>
            import ("../views/Services.vue"),
        meta: {
            title: "Cleaner BG - Услуги",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
        children: [
            ...offices,
            ...homes,
            {
                path: "/services/industry",
                name: "Industry",
                component: () =>
                    import ("../components/includes/services/Industry.vue"),
                meta: {
                    title: "Cleaner BG - Услуги - За Индустриални Обекти",
                    metaTags: [{
                            name: 'description',
                            content: 'Cleaner BG'
                        },
                        {
                            name: 'og:description',
                            content: 'Cleaner BG'
                        }
                    ]
                },
            },
            {
                path: "/services/ddd",
                name: "Ddd",
                component: () =>
                    import ("../components/includes/services/Ddd.vue"),
                meta: {
                    title: "Cleaner BG - Услуги - ДДД",
                    metaTags: [{
                            name: 'description',
                            content: 'Cleaner BG'
                        },
                        {
                            name: 'og:description',
                            content: 'Cleaner BG'
                        }
                    ]
                },
            },
            {
                path: "/services/greening",
                name: "Greening",
                component: () =>
                    import ("../components/includes/services/Greening.vue"),
                meta: {
                    title: "Cleaner BG - Услуги - Озеленяване",
                    metaTags: [{
                            name: 'description',
                            content: 'Cleaner BG'
                        },
                        {
                            name: 'og:description',
                            content: 'Cleaner BG'
                        }
                    ]
                },
            },
            {
                path: "/services/windows",
                name: "Windows",
                component: () =>
                    import ("../components/includes/services/Windows.vue"),
                meta: {
                    title: "Cleaner BG - Услуги - Остъкление",
                    metaTags: [{
                            name: 'description',
                            content: 'Cleaner BG'
                        },
                        {
                            name: 'og:description',
                            content: 'Cleaner BG'
                        }
                    ]
                },
            },
        ],
    },
    {
        path: "/contacts",
        name: "Contacts",
        component: () =>
            import ("../views/Contacts.vue"),
        meta: {
            title: "Cleaner BG - Контакти",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        }
    },
    {
        path: "/policies",
        name: "Policies",
        component: () =>
            import ("../components/Policies.vue"),
        meta: {
            title: "Cleaner BG - Политика за лични данни",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () =>
            import ("../components/NotFound.vue"),
        meta: {
            title: "Cleaner BG - 404",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router;