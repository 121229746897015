/* eslint-disable vue/no-unused-components */
<template>
  <div class="bg-main-custom bg-blue container-fluid align-middle">
    <div class="row">
      <Header class="col align-items-start fixed-top"/>
      <Logo class="mt-5 mb-4"/>
    </div>
    <div class="row">
      <router-view class="col align-items-center"/>
    </div>
    <div class="row">
      <Footer class="col align-items-end" />
    </div>
  </div>
</template>

<script>
  import Header from "./components/Header"
  import Footer from "./components/Footer"
  import Logo from "./components/Logo"

  export default {
    name: "App",
    components: {
      Header,
      Logo,
      Footer,
    }
  }
</script>