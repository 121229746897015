<template>
    <ul class="navbar-nav ml-auto flex-row">
        <li class="d-none nav-item mx-1">
            <router-link class="nav-link" to="/en">
                EN
            </router-link>
        </li>
        <li class="nav-item mx-1">
            <a class="nav-link" target="_blank" href="https://www.facebook.com/CLEANER-238064697194">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook text-primary" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
            </a>
        </li>
        <li class="nav-item mx-1">
            <a class="nav-link" target="_blank" href="https://www.instagram.com/cleanerindustrial/">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 551.034 551.034" style="enable-background:new 0 0 551.034 551.034;" xml:space="preserve" width="20" height="20">
                    
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="275.517" y1="4.57" x2="275.517" y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                        <stop  offset="0" style="stop-color:#E09B3D"/>
                        <stop  offset="0.3" style="stop-color:#C74C4D"/>
                        <stop  offset="0.6" style="stop-color:#C21975"/>
                        <stop  offset="1" style="stop-color:#7024C4"/>
                    </linearGradient>
                    <path style="fill:url(#SVGID_1_);" d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722
                        c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156
                        C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156
                        c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722
                        c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"/>
                    
                        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="275.517" y1="4.57" x2="275.517" y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                        <stop  offset="0" style="stop-color:#E09B3D"/>
                        <stop  offset="0.3" style="stop-color:#C74C4D"/>
                        <stop  offset="0.6" style="stop-color:#C21975"/>
                        <stop  offset="1" style="stop-color:#7024C4"/>
                    </linearGradient>
                    <path style="fill:url(#SVGID_2_);" d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517
                        S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083
                        s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z"/>
                    
                        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="418.31" y1="4.57" x2="418.31" y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                        <stop  offset="0" style="stop-color:#E09B3D"/>
                        <stop  offset="0.3" style="stop-color:#C74C4D"/>
                        <stop  offset="0.6" style="stop-color:#C21975"/>
                        <stop  offset="1" style="stop-color:#7024C4"/>
                    </linearGradient>
                    <circle style="fill:url(#SVGID_3_);" cx="418.31" cy="134.07" r="34.15"/>
                </svg>
            </a>
        </li>
        <li class="nav-item mx-1">
            <a class="nav-link" target="_blank" href="mailto:office@cleaner.bg">
                <svg width="20" height="20" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <circle style="fill:#2196F3;" cx="256" cy="256" r="256"/>
                    <path style="fill:#FAFAFA;" d="M277.216,97.344c-52.352-6.624-102.816,11.392-138.528,49.824
                        c-35.648,38.432-49.952,90.4-39.232,142.624c12.448,60.48,59.232,108.352,119.2,121.984c12.544,2.88,25.088,4.288,37.472,4.288
                        c24.608,0,48.608-5.6,70.816-16.608c7.904-3.936,11.136-13.536,7.232-21.472c-3.968-7.936-13.504-11.104-21.472-7.232
                        c-26.72,13.28-56.8,16.672-86.944,9.824c-47.04-10.688-85.184-49.792-94.944-97.216c-8.64-41.984,2.816-83.68,31.328-114.432
                        c28.544-30.72,68.96-45.216,110.976-39.84c63.232,8.192,110.88,65.6,110.88,133.6V272c0,17.664-14.336,32-32,32s-32-14.336-32-32
                        v-64c0-8.832-7.168-16-16-16c-6.56,0-12.16,3.968-14.624,9.6C279.84,195.584,268.64,192,256,192c-37.696,0-64,29.6-64,72
                        s26.304,72,64,72c19.872,0,36.416-8.384,47.776-22.4C315.52,327.2,332.672,336,352,336c35.296,0,64-28.704,64-64v-9.312
                        C416,178.656,356.352,107.584,277.216,97.344z M256,304c-19.744,0-32-15.328-32-40s12.256-40,32-40s32,15.328,32,40
                        S275.744,304,256,304z"/>
                    </svg>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "Social"
    }
</script>