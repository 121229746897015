<template>
    <div class="why-cleaner cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">ЗА НАС</h4>
                    <p class="text-white mx-5 my-4"><strong>Cleaner</strong> e лидер сред фирмите за професионално почистване в България. Имаме повече от 13 години опит в тази сфера. Нашата отговорност и мисия е да сме перфектни в своята работа до най-малкия детайл. Cleaner разполага множество служители с отлична квалификация.
                    </p>
                    <router-link v-if="button" to="/about" class="btn btn-danger mx-5 mb-5">ВИЖ ОЩЕ</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WhyCleaner",
        props: {
            button: Boolean,
        },
    }
</script>