export const offices = [{
        path: "offices",
        name: "Offices",
        component: () =>
            import ("../../components/includes/services/Offices.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Офиси",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
    {
        path: "offices-subscription",
        name: "OfficesSubscription",
        component: () =>
            import ("../../components/includes/services/offices/OfficesSubscription.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Офиси - Абонаментно почистване",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
    {
        path: "offices-general",
        name: "OfficesGeneral",
        component: () =>
            import ("../../components/includes/services/offices/OfficesGeneral.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Офиси - Основно почистване",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
];