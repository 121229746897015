import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./sass/public.scss";
import moment from 'moment'
import axios from 'axios'

createApp(App).use(store).use(router).mount("#app");

App.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

App.prototype.$http = axios