export const homes = [{
        path: "homes",
        name: "Homes",
        component: () =>
            import ("../../components/includes/services/Homes.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Домове",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
    {
        path: "homes-standard",
        name: "HomesStandard",
        component: () =>
            import ("../../components/includes/services/homes/HomesStandard.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Домове - Стандартно",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
    {
        path: "homes-fine",
        name: "HomesFine",
        component: () =>
            import ("../../components/includes/services/homes/HomesFine.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Домове - Фино",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
    {
        path: "homes-healthy",
        name: "HomesHealthy",
        component: () =>
            import ("../../components/includes/services/homes/HomesHealthy.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Домове - Здравословно",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
    {
        path: "homes-after-construction",
        name: "HomesAfterConstruction",
        component: () =>
            import ("../../components/includes/services/homes/HomesAfterConstruction.vue"),
        meta: {
            title: "Cleaner BG - Услуги - За Домове - След ремонт",
            metaTags: [{
                    name: 'description',
                    content: 'Cleaner BG'
                },
                {
                    name: 'og:description',
                    content: 'Cleaner BG'
                }
            ]
        },
    },
];