/* eslint-disable prettier/prettier */
<template>
    <footer class="footer text-white text-center py-5">
        <h1 class="col py-3">Фирма за почистване Cleaner</h1>
        <div class="container">
            <h3 class="py-2"><router-link to="/policies">Политика за лични данни</router-link> | {{ currentYear }} © Клийнър Груп | Всички права запазени.</h3>
            <h3>Created by Radin Nedkov</h3>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                currentYear: new Date().getFullYear(),
            }
        }
    }
</script>