<template>
    <div class="email-contact container my-4 py-5 justify-content-center text-center shadow-lg">
        <transition name="fade" mode="out-in">
            <div v-if="sent">
                <h4>Заявката Ви е изпратена.</h4>
            </div>
        </transition>
        <div v-if="!sent" class="form-group container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 py-3 text-left">
                    <p>Използвайте тази форма за да изпратите Вашето запитване. Ще се свържем с Вас възможно най-скоро.</p>
                </div>
            </div>
            <form @submit="onSubmit">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 py-3">
                        <input class="form-control mb-3 border-bottom-3 border-top-0 border-left-0 border-right-0" 
                        id="inlineFormInputGroup" 
                        placeholder="Имена"
                        v-model="form.names" 
                        required 
                        oninvalid="this.setCustomValidity('Моля въведете имената си.')" 
                        oninput="this.setCustomValidity('')">
                    </div>
                    <div class="col-12 col-md-4 py-3">
                        <input class="form-control mb-3 border-bottom-3 border-top-0 border-left-0 border-right-0" 
                        id="inlineFormInputGroup" 
                        placeholder="Град"
                        v-model="form.city">
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 py-3">
                        <input class="form-control mb-3 border-bottom-3 border-top-0 border-left-0 border-right-0" 
                        id="inlineFormInputGroup" 
                        placeholder="Телефон"
                        v-model="form.phoneNumber"
                        pattern = "\d{10,10}" 
                        title='Моля въведете телефонен номер.' 
                        required 
                        oninvalid="this.setCustomValidity('Моля въведете телефонния си номер - 10 знака.')" 
                        oninput="this.setCustomValidity('')">
                    </div>
                    <div class="col-12 col-md-4 py-3">
                        <input class="form-control mb-3 border-bottom-3 border-top-0 border-left-0 border-right-0" 
                        id="inlineFormInputGroup" 
                        placeholder="E-mail"
                        v-model="form.email"
                        type="email"
                        title='Моля въведете имейл адрес.' 
                        required 
                        oninvalid="this.setCustomValidity('Невалиден имейл адрес.')" 
                        oninput="this.setCustomValidity('')">
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 py-3">
                        <textarea class="form-control mb-3" 
                        rows="5"
                        id="inlineFormInputGroup" 
                        placeholder="Съобщение"
                        v-model="form.text"
                        type="text"
                        title='Моля въведете текст.' 
                        required></textarea>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-12 col-md-6">
                       <button class="send-button btn btn-danger px-4" type="submit">ИЗПРАТИ</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
const querystring = require("querystring");
import axios from 'axios';

export default {
    name: 'EmailContact',
    data() {
        return {
          sent: false,
          form: {
              phoneNumber: null,
              names: '',
              email: null,
              text: '',
              title: 'Ново съобщение от cleaner.bg',
          },
        };
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            axios
                .post(
                    "https://cleaner.bg/mail.php",
                    querystring.stringify(this.form)
                )
                // eslint-disable-next-line no-unused-vars
                .then(res => {
                    this.sent = true;
                });
        },
    }
};
</script>