<template>
  <div class="home-page px-0">
    <Categories />
    <WhyCleaner :button="true" />
    <div class="container mb-5">
      <div class="row">
        <div class="col-auto mt-5">
          <h4 class="contact-title pb-2 border-bottom border-top-0 border-left-0 border-right-0">СВЪРЖЕТЕ СЕ С НАС</h4>
        </div>
      </div>
      <div class="row">
          <EmailContact />
      </div>
    </div>
  </div>
</template>

<script>
import Categories from "../components/includes/Categories"
import WhyCleaner from "../components/includes/WhyCleaner"
import EmailContact from "../components/includes/contacts/EmailContact"

export default {
  name: "Home",
  components: {
    Categories,
    WhyCleaner,
    EmailContact,
  }
};
</script>