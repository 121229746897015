<template>
  <div class="categories-wrapper container text-center">
    <div class="row">
      <div class="col-12 col-lg-4 my-4" @mouseenter="isOfficesActive = true" @mouseleave="isOfficesActive = false">
        <router-link class="category-h column-1 d-inline-block w-100" to="/services/offices">
          <transition name="slide" type="animation" mode="out-in" appear>
            <span class="caption" v-show="isOfficesActive">
              <p class="p-4">Cleaner предлага професионално чистене на офиси с различна площ и степен на сложност. Качеството на нашите услуги гарантира блестяща работна обстановка и отлична атмосфера за работа. 
              </p>
              </span>
          </transition>
          <span class="title">За Офиси</span>
        </router-link>
      </div>
      <div class="col-12 col-lg-4 my-4" @mouseenter="isHomesActive = true" @mouseleave="isHomesActive = false">
        <router-link class="category-h column-2 d-inline-block w-100" to="/services/homes">
          <transition name="slide" type="animation" mode="out-in" appear>
            <span class="caption" v-show="isHomesActive">
              <p class="p-4">Cleaner предлага професионално чистене на офиси с различна площ и степен на сложност. Качеството на нашите услуги гарантира блестяща работна обстановка и отлична атмосфера за работа. 
              </p>
              </span>
          </transition>
          <span class="title">За Домове</span>
        </router-link>
      </div>
      <div class="col-12 col-lg-4 my-4" @mouseenter="isIndustryActive = true" @mouseleave="isIndustryActive = false">
        <router-link class="category-h column-3 d-inline-block w-100" to="/services/industry">
          <transition name="slide" type="animation" mode="out-in" appear>
            <span class="caption" v-show="isIndustryActive">
              <p class="p-4">Cleaner предлага професионално чистене на офиси с различна площ и степен на сложност. Качеството на нашите услуги гарантира блестяща работна обстановка и отлична атмосфера за работа. 
              </p>
              </span>
          </transition>
          <span class="title">За Индустриални обекти</span>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4 my-4" @mouseenter="isDddActive = true" @mouseleave="isDddActive = false">
        <router-link class="category-w column-4 d-inline-block w-100" to="/services/ddd">
          <transition name="slide" type="animation" mode="out-in" appear>
            <span class="caption" v-show="isDddActive">
              <p class="p-4">Cleaner предлага професионално чистене на офиси с различна площ и степен на сложност.
              </p>
              </span>
          </transition>
          <span class="title">ДДД</span>
        </router-link>
      </div>
      <div class="col-12 col-lg-4 my-4" @mouseenter="isGreeningActive = true" @mouseleave="isGreeningActive = false">
        <router-link class="category-w column-5 d-inline-block w-100" to="/services/greening">
          <transition name="slide" type="animation" mode="out-in" appear>
            <span class="caption" v-show="isGreeningActive">
              <p class="p-4">Cleaner предлага професионално чистене на офиси с различна площ и степен на сложност.
              </p>
              </span>
          </transition>
          <span class="title">Озеленяване</span>
        </router-link>
      </div>
      <div class="col-12 col-lg-4 my-4" @mouseenter="isWindowsActive = true" @mouseleave="isWindowsActive = false">
        <router-link class="category-w column-6 d-inline-block w-100" to="/services/windows">
          <transition name="slide" type="animation" mode="out-in" appear>
            <span class="caption" v-show="isWindowsActive">
              <p class="p-4">Cleaner предлага професионално чистене на офиси с различна площ и степен на сложност.
              </p>
              </span>
          </transition>
          <span class="title">Остъкление</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
      name: "Categories",
      data() {
        return {
          isHomesActive: false,
          isOfficesActive: false,
          isIndustryActive: false,
          isDddActive: false,
          isGreeningActive: false,
          isWindowsActive: false
        }
      }
  }
</script>