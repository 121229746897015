<template>
    <router-link class="mx-auto" to="/">
        <div class="container mt-5">
            <div class="logo-wrap row justify-content-center">
                <div class="col-10 col-md-4 px-0 mr-md-0 my-auto">
                    <img class="logo-image img-fluid h-auto" src="../assets/logo_cleaner3.png" alt="Cleaner Logo">
                </div>
                <div class="col-12 col-md-12 text-center mt-3">
                    <span class="logo-text d-md-inline-block">Професионално почистване и поддръжка</span>
                </div>
            </div>
        </div>            
    </router-link> 
</template>

<script>
    export default {
        name: "Logo",
    }
</script>